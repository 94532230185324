import { Route, Routes } from "react-router-dom"
import React from "react";

const RedirectPage = ({link}) => {
  React.useEffect(() => {
    window.location.replace(link)
  }, [link])
  return null
}

export default function App() {
  return (
    <Routes>
      <Route path='*' element={<RedirectPage link={'https://cheapcc.net'} />}/>
    </Routes>
  );
}